@use 'styles/new-design/mixins/' as mixins;
$stepperHeaderHeight: calc(3 * var(--ds-spacing));
$h2StepperMarginTop: calc(2 * var(--ds-spacing));

.mat-stepper-vertical:not([class*='default']),
.mat-stepper-horizontal:not([class*='default']) {
  background: var(--ds-neutral-10);
  height: 100%;

  .mat-step-icon-state-done,
  .mat-step-icon-state-edit {
    background-color: var(--ds-success-500);
    color: var(--ds-white);
  }

  .mat-step-icon-selected {
    background-color: var(--ds-info-500);
    color: var(--ds-white);
  }
}

.mat-stepper-vertical:not([class*='default']) {
  .mat-step-header[ng-reflect-active='true']:not([ng-reflect-selected='true'])
    + .mat-vertical-content-container::before {
    border-left-color: var(--ds-success-500);
    border-width: 2px;
  }
}

.mat-stepper-horizontal:not([class*='default']) {
  .mat-horizontal-stepper-wrapper {
    height: 100%;
  }
  .mat-horizontal-stepper-header-container:not([class*='default']) {
    flex: 0 0 auto;
  }
  .mat-horizontal-content-container:not([class*='default']) {
    flex: 1 1 auto;
    min-height: 0;
    overflow: auto;
  }

  div.mat-horizontal-stepper-content:not(
      [class*='mat-horizontal-stepper-content-inactive']
    ) {
    height: 100%;
  }

  .mat-stepper-horizontal-line:has(~ .mat-step-header[aria-selected='true']) {
    border-top-color: var(--ds-success-500);
    border-width: 2px;
  }
}
