@use 'styles/new-design/mixins' as mixins;

$stepperSidebarSize: calc(18 * var(--ds-spacing));

.stepper-with-sidebar-content {
  padding-right: $stepperSidebarSize;

  @include mixins.media-lt-md {
    padding-right: unset;
  }
}
